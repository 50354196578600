import axiosInstance from "@app/axiosInstance";
import { normalizeDates, removeEmptyFields } from "@app/modules/utilities";
import { useCompany } from "@app/providers/CompanyContextProvider";
import { useQuery } from "@tanstack/react-query";

export const useMemberQueries = () => {
  const { selectedCompany } = useCompany();
  const companyId = selectedCompany?.companyId;

  const getMembers = () => {
    const { data: members, isPending: isMembersPending } = useQuery({
      queryKey: ["members", companyId],
      queryFn: () =>
        axiosInstance
          .get(`/admin/${companyId}/member`)
          .then((response) =>
            response.data.map(normalizeDates).map(removeEmptyFields),
          ),
      throwOnError: true,
      enabled: !!companyId,
    });

    return { members, isMembersPending };
  };

  return { getMembers };
};
