import { useCompanyMutations } from "@app/entities/company/mutations";
import { T_Company } from "@app/entities/company/types";
import { loadStripe } from "@stripe/stripe-js";
import notification from "antd/lib/notification";
import { useEffect, useState } from "react";

export const useRegisterCompany = () => {
  const [stripe, setStripe] = useState<any>();
  const [showLegalText, setShowLegalText] = useState(false);
  const [companyData, setCompanyData] = useState<T_Company>();
  const { registerCompany, checkout, isMutationPending } =
    useCompanyMutations();

  useEffect(() => {
    (async () => {
      const stripeResponse = await loadStripe(
        process.env.STRIPE_PUBLIC_KEY || "",
      );
      setStripe(stripeResponse);
    })();

    // Override browser back button to close legal text
    let preventPopstate = false;

    // Initial state setup
    window.history.pushState(null, document.title, window.location.href);

    // Event listener for popstate
    const handlePopstate = () => {
      if (preventPopstate) {
        // Reset the flag
        preventPopstate = false;
        return;
      }

      setShowLegalText((prevState: boolean) => {
        if (!prevState) {
          // If showLegalText is false, navigate to the previous page
          window.history.back();
          return false;
        }

        // If showLegalText is true, set it to false without navigating back
        window.history.pushState(null, document.title, window.location.href);
        return false;
      });
    };

    window.addEventListener("popstate", handlePopstate);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  const handleFormSubmit = (companyData: T_Company) => {
    setCompanyData(companyData);
    setShowLegalText(true);
    // The form is expecting a boolean that will determine whether to clear the form fields or not
    // returning `true` will clear the form fields
    return true;
  };

  const redirectToStripe = async () => {
    if (!stripe) {
      return;
    }
    if (!companyData) {
      return;
    }
    registerCompany(companyData, {
      onSuccess: async ({ companyId }) => {
        if (companyId) {
          const returnTo = `${window.location.origin}/#/company/${companyId}`;
          return checkout(
            {
              cancelUrl: returnTo,
              companyId,
              successUrl: returnTo,
            },
            {
              onSuccess: async ({ sessionId }) => {
                const { error } = await stripe.redirectToCheckout({
                  sessionId,
                });
                if (error) {
                  console.error(error);
                  notification.error({
                    message: "Failed to redirect to checkout",
                  });
                }
              },
            },
          );
        }
      },
    });
  };

  const isLoading = isMutationPending;
  return {
    stripe,
    isLoading,
    showLegalText,
    companyData,
    handleFormSubmit,
    redirectToStripe,
  };
};
