import { useAdminMutations } from "@app/entities/admin/mutations";
import axios from "axios";
import { useEffect } from "react";

const isLive = ["production", "staging"].includes(process.env.NODE_ENV ?? "");

const axiosInstance = axios.create({
  baseURL: isLive ? `/api` : `http://localhost:8335/api`,
  withCredentials: true,
  headers: {
    post: {
      ...axios.defaults.headers.post,
      "Content-Type": "application/json",
    },
  },
});

export const setupAxiosInterceptors = (logout: () => void) => {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log("\n\n\n IN THE HTINGS");
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        logout();
      }
      return Promise.reject(error); // Ensure the error is thrown to be handled by React Query
    },
  );
};

export const useAxiosInterceptor = () => {
  const { logout } = useAdminMutations();

  useEffect(() => {
    setupAxiosInterceptors(logout);
  }, [logout]);
};

export default axiosInstance;
