import axiosInstance from "@app/axiosInstance";
import { T_Login } from "@app/entities/admin/types";
import { useCompany } from "@app/providers/CompanyContextProvider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import notification from "antd/lib/notification";
import { useNavigate } from "react-router";

export const useAdminMutations = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { selectedCompany } = useCompany();
  const companyId = selectedCompany?.companyId;

  const { mutate: logout, isPending: isLogoutPending } = useMutation({
    mutationFn: () => axiosInstance.delete("/oauth/logout"),
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes(companyId),
      });
      navigate("/");
    },
    onError: (error) => {
      console.error(error);
      notification.error({
        description: "Something went wrong logging you out.",
        message: "Error",
      });
      navigate("/");
    },
  });

  const { mutate: sendMagicLink, isPending: isSendMagicLinkPending } =
    useMutation({
      mutationFn: ({ email, recaptchaToken }: T_Login) =>
        axiosInstance.post("/oauth/magic-login/admin", {
          destination: email,
          recaptchaToken,
        }),
      onSuccess: () => {
        notification.success({
          description: "Success! Check for your email for a link to login.",
          message: "Success",
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey.includes("admin"),
        });
      },
      onError: (error) => {
        notification.error({
          description: error.message,
          message:
            "Something went wrong sending a link. Please refresh the page and try again.",
        });
      },
    });

  const { mutate: makeAdminOwner, isPending: isMakeAdminOwnerPending } =
    useMutation({
      mutationFn: ({ entityId }: { entityId: number }) =>
        axiosInstance.put(`/admin/${companyId}/make-owner`, {
          entityId,
        }),
      onError: (error) => {
        console.error(error);
        notification.error({ message: "Failed to make admin owner" });
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["company", companyId] });
        notification.success({
          description: "Admin has been made owner",
          message: "Success",
        });
        navigate(`/admin/dashboard?tab=details`);
      },
    });

  const {
    mutate: removeAdminFromCompany,
    isPending: isRemoveAdminFromCompanyPending,
  } = useMutation({
    mutationFn: ({ entityId }: { entityId: number }) =>
      axiosInstance.delete(`/admin/${companyId}/admin`, {
        data: { entityId },
      }),
    onError: (error) => {
      console.error(error);
      notification.error({ message: "Failed to remove admin from company" });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admins"] });
      notification.success({
        description: "Admin has been removed from company",
        message: "Success",
      });
    },
  });

  const isMutationPending =
    isMakeAdminOwnerPending ||
    isLogoutPending ||
    isRemoveAdminFromCompanyPending ||
    isSendMagicLinkPending;
  return {
    isMutationPending,
    logout,
    makeAdminOwner,
    removeAdminFromCompany,
    sendMagicLink,
  };
};
