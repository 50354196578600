import axiosInstance from "@app/axiosInstance";
import {
  T_Member,
  T_MemberAvailability,
  T_MemberStatus,
} from "@app/entities/member/types";
import { useCompany } from "@app/providers/CompanyContextProvider";
import { useMember } from "@app/providers/MemberContextProvider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import notification from "antd/lib/notification";

export const useMemberMutations = () => {
  const queryClient = useQueryClient();
  const { selectedMember } = useMember();
  const { selectedCompany } = useCompany();
  const companyId = selectedCompany?.companyId;

  const { mutate: updateMember, isPending: isUpdateMemberPending } =
    useMutation({
      mutationFn: (member: T_Member) =>
        axiosInstance.put(
          `/admin/${companyId}/member/${selectedMember?.entityId}`,
          member,
        ),
      onError: (error) => {
        console.error(error);
        notification.error({ message: "Failed to update member" });
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["members"] });
        notification.success({
          description: "Member has been updated",
          message: "Success",
        });
      },
    });

  const {
    mutate: updateMemberAvailability,
    isPending: isUpdateMemberAvailabilityPending,
  } = useMutation({
    mutationFn: (availability: Partial<T_MemberAvailability>) =>
      axiosInstance.put(
        `/admin/${companyId}/member/${selectedMember?.entityId}/availability`,
        availability,
      ),
    onError: (error) => {
      console.error(error);
      notification.error({ message: "Failed to update member availability" });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["members"] });
      notification.success({
        description: "Member availability has been updated",
        message: "Success",
      });
    },
  });

  const { mutate: updateMemberStatus, isPending: isUpdateMemberStatusPending } =
    useMutation({
      mutationFn: ({ status }: { status: T_MemberStatus }) =>
        axiosInstance.put(
          `/admin/${companyId}/member/${selectedMember?.entityId}/company`,
          {
            status,
          },
        ),
      onError: (error) => {
        console.error(error);
        notification.error({ message: "Failed to update member status" });
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["members"] });
        notification.success({
          description: "Member status has been updated",
          message: "Success",
        });
      },
    });

  const isMutationPending =
    isUpdateMemberPending ||
    isUpdateMemberAvailabilityPending ||
    isUpdateMemberStatusPending;
  return {
    isMutationPending,
    updateMember,
    updateMemberAvailability,
    updateMemberStatus,
  };
};
