import { T_DateRange } from "@app/_shared_components/DateFilter";
import { useReportQueries } from "@app/entities/report/queries";
import { T_Report } from "@app/entities/report/types";
import dayjs from "dayjs";
import { ExportToCsv } from "export-to-csv";
import { useState } from "react";

const options = {
  filename: `Stats-${new Date().toLocaleDateString().replace(/\//gi, ".")}`,
  useKeysAsHeaders: true,
};
const csvExporter = new ExportToCsv(options);

export const useStatsLoader = () => {
  const [showActive, setShowActive] = useState(true);
  const [showBad, setShowBad] = useState(true);
  const [showClaimed, setShowClaimed] = useState(true);
  const [fromDate, setFromDate] = useState(
    dayjs(new Date()).startOf("month").format("YYYY-MM-DD"),
  );
  const [toDate, setToDate] = useState(
    dayjs(new Date()).endOf("month").format("YYYY-MM-DD"),
  );
  const { getAllReports } = useReportQueries();
  const { allReports, isAllReportsPending } = getAllReports({
    fromDate,
    toDate,
  });

  const handleDateChange = async ({ fromDate, toDate }: T_DateRange) => {
    setFromDate(dayjs(fromDate).startOf("month").format("YYYY-MM-DD"));
    setToDate(dayjs(toDate).endOf("month").format("YYYY-MM-DD"));
  };

  const handleExportStats = () => {
    if (!allReports?.length) return;
    csvExporter.generateCsv(allReports);
  };

  function filterReports(report: T_Report): boolean {
    switch (report.status) {
      case "a":
        return showActive;
      case "b":
        return showBad;
      case "c":
        return showClaimed;
      default:
        return false;
    }
  }

  const activeReportCount = allReports?.filter(
    (report: T_Report) => report.status === "a",
  ).length;
  const badReportCount = allReports?.filter(
    (report: T_Report) => report.status === "b",
  ).length;
  const claimedReportCount = allReports?.filter(
    (report: T_Report) => report.status === "c",
  ).length;

  const isLoading = isAllReportsPending;
  return {
    reports: allReports?.filter(filterReports),
    isLoading,
    showActive,
    showBad,
    showClaimed,
    activeReportCount,
    badReportCount,
    claimedReportCount,
    handleDateChange,
    handleExportStats,
    setShowActive,
    setShowBad,
    setShowClaimed,
  };
};
