import axiosInstance from "@app/axiosInstance";
import { T_Company } from "@app/entities/company/types";
import { normalizeDates, removeEmptyFields } from "@app/modules/utilities";
import { useCompany } from "@app/providers/CompanyContextProvider";
import { useQuery } from "@tanstack/react-query";
import { ExportToCsv } from "export-to-csv";
import { useMemo } from "react";

export const useCompanyQueries = () => {
  const { selectedCompany } = useCompany();
  const companyId = selectedCompany?.companyId;

  const fileNames = useMemo(
    () => ({
      badReports: `${new Date().toLocaleDateString().replace(/\//gi, ".")}-${
        selectedCompany?.name
      }-Bad_Reports`,
      claimedReports: `${new Date()
        .toLocaleDateString()
        .replace(/\//gi, ".")}-${selectedCompany?.name}-Claimed_Reports`,
      members: `${new Date().toLocaleDateString().replace(/\//gi, ".")}-${
        selectedCompany?.name
      }-Members`,
      reportAlerts: `${new Date().toLocaleDateString().replace(/\//gi, ".")}-${
        selectedCompany?.name
      }-Report_Alerts`,
      payments: `${new Date().toLocaleDateString().replace(/\//gi, ".")}-${
        selectedCompany?.name
      }-Payments`,
    }),
    [selectedCompany],
  );

  const getCompanies = () => {
    const { data: companies, isPending: isCompaniesPending } = useQuery({
      queryKey: ["companies"],
      queryFn: (): Promise<T_Company[]> =>
        axiosInstance
          .get("/admin/company")
          .then((response) =>
            response.data.map(normalizeDates).map(removeEmptyFields),
          ),
      throwOnError: true,
    });

    return { companies, isCompaniesPending };
  };

  const getCompanyData = () => {
    const {
      data: companyData,
      isPending: isCompanyDataPending,
      isFetching: isCompanyDataFetching,
      refetch: fetchCompanyData,
    } = useQuery({
      queryKey: ["company", "all-data", companyId],
      queryFn: () =>
        axiosInstance
          .get(`/admin/company/${companyId}/all-data`)
          .then((response) => response.data)
          .then((companyData) => {
            Object.entries(companyData).forEach(([key, value]) => {
              if (!Array.isArray(value) || !value?.length) {
                return null;
              }
              const csvExporter = new ExportToCsv({
                filename: fileNames[key as keyof typeof fileNames],
                useKeysAsHeaders: true,
              });
              csvExporter.generateCsv(
                value.map(normalizeDates).map(removeEmptyFields),
              );
            });
            return companyData;
          }),
      throwOnError: true,
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retryOnMount: false,
    });

    return {
      companyData,
      isCompanyDataPending,
      isCompanyDataFetching,
      fetchCompanyData,
    };
  };

  return {
    getCompanies,
    getCompanyData,
  };
};
