import { useReportMutations } from "@app/entities/report/mutations";
import { T_Report } from "@app/entities/report/types";
import Form from "antd/lib/form";

type ReportFormLoaderProps = {
  reportId: number;
  closeModal: () => void;
};

export const useReportFormLoader = ({
  reportId,
  closeModal,
}: ReportFormLoaderProps) => {
  const [reportForm] = Form.useForm();
  const { updateReport, deleteReport, markReportBad, isMutationPending } =
    useReportMutations();

  const handleDeleteReport = (reportId: number) => {
    deleteReport(reportId, { onSuccess: closeModal });
  };
  const handleMarkReportBad = (reason: string) => {
    markReportBad(
      {
        reportId,
        reason,
      },
      { onSuccess: closeModal },
    );
  };

  const handleUpdateReport = (report: T_Report) => {
    updateReport(
      { ...report, reportId },
      {
        onSuccess: () => {
          closeModal();
        },
      },
    );
  };

  const isLoading = isMutationPending;
  return {
    handleUpdateReport,
    handleDeleteReport,
    handleMarkReportBad,
    reportForm,
    isLoading,
  };
};
